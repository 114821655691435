import React from "react"
import styled from "styled-components"
// import { rem } from "polished"
import PropTypes from "prop-types"

import Spacer from "../spacer"

import Row from "../grid/row"
import Col from "../grid/column"

import Container from "../container"

const Wrapper = styled.div`
  color: ${(props) => props.textColor};
  background: ${(props) => props.bgColor};
  min-height: 80vh;
  display: flex;
`

const Heading = styled.h1.attrs({ className: `styled-h3` })``

const StyledContainer = styled(Container)`
  align-self: flex-end;
`

const Hero = (props) => (
  <Wrapper bgColor={props.bgColor} textColor={props.textColor}>
    <StyledContainer>
      <Row>
        <Col smallUp={8} smallUpOffset={3}>
          <Spacer top bottom>
            <Heading dangerouslySetInnerHTML={{ __html: props.title }} />
          </Spacer>
        </Col>
      </Row>
    </StyledContainer>
  </Wrapper>
)

Hero.propTypes = {
  bgColor: PropTypes.any,
  textColor: PropTypes.any,
  title: PropTypes.string,
}

export default Hero
